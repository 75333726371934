import { createTheme, ThemeOptions } from '@mui/material';

import { defaultThemeOptions, ThemeProvider as TulpThemProvider } from './tulp/theme';

declare module '@mui/material/styles' {
  interface Theme {
    feed: {
      cardWidth: number;
      cardHeight: number;
    };
    groupHookup: {
      cardHeight: number;
      cardPadding: number;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    feed?: {
      cardWidth?: number;
      cardHeight?: number;
    };
    groupHookup?: {
      cardHeight?: number;
      cardPadding?: number;
    };
  }
}

const appThemeOptions: ThemeOptions = {
  ...defaultThemeOptions,
  feed: {
    cardHeight: 90,
    cardWidth: 219
  },
  groupHookup: {
    cardHeight: 350,
    cardPadding: 2
  }
};

export const AppTheme = createTheme(appThemeOptions);

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => <TulpThemProvider theme={AppTheme}>{children}</TulpThemProvider>;
